@import "https://fonts.googleapis.com/css?family=Raleway";
html {
  height: 100%;
  width: 100%;
}
body {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.float-left {
  margin: 0;
  float: left;
}
.float-right {
  margin: 0;
  float: right;
}
hr {
  clear: both;
  border: none;
  margin: 0.5rem;
  padding: 0;
}
.container {
  position: relative;
  margin: 2rem auto;
  width: 100%;
  max-width: 1200px;
}
.container.condensed {
  margin: 0.5rem auto;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 850px) {
  .flex-column {
    align-items: center;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.flex-row.centered {
  justify-content: center;
}
@media (max-width: 850px) {
  .flex-row {
    flex-direction: column;
  }
}
.flex-inline-child {
  flex: 1 0 0;
  margin: 0.5rem auto;
  max-width: 95%;
}
.flex-column-child {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
}
.half {
  margin-left: auto;
  margin-right: auto;
  min-width: 50%;
  max-width: 50%;
}
@media (max-width: 850px) {
  .half {
    min-width: 90%;
    max-width: 90%;
  }
}
.small {
  min-width: 30%;
  max-width: 30%;
}
@media (max-width: 850px) {
  .small {
    max-width: 80%;
    margin: 1rem;
  }
}
* {
  box-sizing: border-box;
}
body {
  font-family: "Raleway", Helvetic, sans-serif, arial;
  color: #161F32;
  -webkit-font-smoothing: antialiased;
  background: #E0E0E0;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.6em;
}
p {
  line-height: 160%;
}
p.important {
  color: #9E9E9E;
  font-size: 1.3rem;
  font-style: italic;
}
p.emphasis {
  font-size: 1.2rem;
}
p.subtle {
  color: #9E9E9E;
  font-style: italic;
}
a {
  color: #3A4355;
}
img {
  border: none;
}
table {
  border: none;
  width: 100%;
}
td {
  padding: 0.3rem;
}
blockquote {
  margin: 2rem 0.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 0.2rem 1rem;
  border-left: 3px solid #161F32;
}
.padded {
  padding: 1rem;
}
.no-margin {
  margin: 0;
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.title-emphasis {
  text-align: center;
}
.title-emphasis:before {
  content: " ";
  position: absolute;
  width: 50%;
  min-width: 100px;
  background: rgba(58, 67, 85, 0.1);
  height: 0.5rem;
  left: 0;
  right: 0;
  z-index: 0;
  margin: 16px auto;
}
.box {
  min-width: 30%;
  margin: 1rem;
  padding: 1rem;
  background: #FAFAFA;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
}
@media (max-width: 450px) {
  .box {
    margin: 2rem 0;
  }
}
.box.no-padding {
  padding: 0;
}
.box.left {
  border-left: 3px solid #161F32;
}
.box.right {
  border-right: 3px solid #161F32;
}
.box.emphasis {
  border-bottom: 3px solid #161F32;
}
.box.dark {
  background-color: #3A4355;
  border: 1px solid #161F32;
  color: #FAFAFA;
}
.box.inner {
  margin: 1.5rem auto;
  max-width: 80%;
}
@media (max-width: 450px) {
  .box.inner {
    max-width: 90%;
  }
}
.container-accent {
  position: relative;
  margin: 0.5rem 0;
  padding: 1rem 0;
}
.container-accent:before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #9E9E9E;
  transform: skewY(3deg);
}
img {
  object-position: center;
  object-fit: fill;
  object-fit: cover;
}
img.small {
  margin: 0 1rem;
  max-width: 40%;
}
@media (max-width: 850px) {
  img.small {
    max-width: 70%;
    margin: 1rem;
    min-width: 150px;
  }
}
img.v-small {
  margin: 0 1rem;
  max-width: 25%;
}
@media (max-width: 850px) {
  img.v-small {
    max-width: 40%;
    margin: 1rem;
    min-width: 150px;
  }
}
.framed {
  background: #FAFAFA;
  padding: 0.4rem;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
}
.button {
  display: inline-block;
  padding: 0.6em 1.6em;
  margin: 1rem 0.5rem;
  text-align: center;
  font-size: 1em;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 0.2rem;
  cursor: pointer;
  color: #E0E0E0;
  background-color: #3A4355;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.5s ease;
}
.button:hover {
  background-color: #161F32;
}
.image-heading {
  padding: 0 0.5rem;
}
.image-heading.coffee {
  background-image: url("coffee.d21d442e.png");
}
.image-heading.cakes {
  background-image: url("cakes.43791876.png");
}
.image-heading .text {
  font-size: 2rem;
  display: inline-block;
  margin: 0.6rem;
  padding: 1rem 2rem;
  color: #FAFAFA;
  background-color: rgba(22, 31, 50, 0.8);
}
.map, .map-fallback {
  width: 100%;
  height: 16rem;
  background-position: center;
}
.map-fallback {
  background-image: url("map.feae48cb.png");
}
header {
  display: block;
  text-align: center;
  padding: 1rem 0 2rem;
  margin: 0 0 2rem;
  background: linear-gradient(150deg, #58412F 0%, #58412F 50%, #4e3a2a 50%, #4b3728 100%);
}
header h2 {
  color: #FAFAFA;
  margin: 3rem 0;
}
header img {
  max-width: 90%;
}
header.condensed {
  padding: 1rem 0 0.5rem;
  text-align: left;
}
header.condensed img {
  margin: 0 0.5rem;
  height: 50px;
}
@media (max-width: 850px) {
  header.condensed img {
    height: 40px;
  }
}
header.condensed h2 {
  display: inline-block;
  margin: 0.9rem;
  vertical-align: top;
}
@media (max-width: 850px) {
  header.condensed h2 {
    font-size: 1.3rem;
  }
}
@media (max-width: 450px) {
  header.condensed h2 {
    display: none;
  }
}
header.condensed nav {
  float: right;
}
nav {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  background-color: #FAFAFA;
  padding: 1rem 0.5rem;
  text-align: left;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  transition: border-radius 0.4s linear;
}
@media (max-width: 850px) {
  nav.condensed {
    padding: 0.4rem 0.4rem 0.2rem 0;
  }
}
nav:hover {
  border-radius: 0.2rem;
}
nav:hover.condensed {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.nav-bar {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-bar li {
  display: inline-block;
  margin: 0;
}
@media (min-width: 450px) {
  .nav-bar li.narrow {
    display: none;
  }
}
@media (max-width: 450px) {
  .nav-bar li.wide {
    display: none;
  }
}
.nav-bar li.tablet {
  display: none;
}
@media (max-width: 850px) {
  .nav-bar li.tablet {
    display: block;
  }
}
@media (max-width: 850px) {
  .nav-bar li.desktop {
    display: none;
  }
}
.nav-bar li a {
  color: #3A4355;
  text-decoration: none;
  font-size: 1.1em;
  padding: 0.5em;
  transition: all 0.4s ease;
  margin: 0 0.2rem;
  border-radius: 0.1rem;
}
.nav-bar li a.open-menu {
  padding: 0;
  margin: 0 0.5rem 0 0;
}
.nav-bar li a.open-menu img {
  width: 30px;
  height: 30px;
}
.nav-bar li a:hover {
  border-radius: 0.2rem;
  color: #E0E0E0;
  background-color: #3A4355;
}
.nav-bar li a:hover.open-menu {
  background: none;
}
.mobile-nav-button {
  background-image: url("menu.db0879a8.png");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: relative;
  display: none;
  padding-right: 0.8rem;
}
.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  transition: visibility 0.3s ease-in, opacity 0.3s ease-in;
}
.overlay.active {
  opacity: 1;
  visibility: visible;
}
.mobile-nav {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 101;
  height: 100%;
  padding: 1rem;
  width: 250px;
  top: 0;
  right: -275px;
  background: url("cup.46e1922e.png") 100% 100% no-repeat #161F32;
  transition: transform 0.5s ease;
  transform: translateX(0);
}
.mobile-nav.active {
  transform: translateX(-275px);
}
.mobile-nav .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.mobile-nav .close img {
  height: 35px;
  width: 35px;
}
.mobile-nav ul {
  margin: 0 0 5rem;
  padding: 0;
  list-style-type: none;
}
.mobile-nav a {
  color: #E0E0E0;
  font-size: 1.4em;
  text-decoration: none;
}
.mobile-nav li {
  position: relative;
  margin: 4rem 0;
}
.mobile-nav li:after {
  content: "";
  position: absolute;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 0.2rem;
  background-color: #E0E0E0;
  bottom: -2rem;
}
.mobile-nav li:last-of-type:after {
  display: none;
}
footer {
  margin: 2rem 0 0;
  padding: 2rem;
  background: linear-gradient(150deg, #161F32 0%, #161F32 70%, #1b263d 70%, #1c2840 100%);
  color: #E0E0E0;
  text-align: center;
}
footer a {
  color: #E0E0E0;
}
.blink {
  animation: 1.2s blink infinite;
}
@keyframes blink {
  0%, 100% {
    color: #FAFAFA;
  }
  50% {
    color: transparent;
  }
}
/*# sourceMappingURL=error.8336d19e.css.map */
