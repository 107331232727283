html {
  height: 100%;
  width: 100%;
}
body {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.float-left {
  margin: 0;
  float: left;
}

.float-right {
  margin: 0;
  float: right;
}

hr {
  clear: both;
  border: none;
  margin: 0.5rem;
  padding: 0;
}

.container {
  position: relative;
  margin: 2rem auto;
  width: 100%;
  max-width: $max-doc-width;

  &.condensed {
    margin: 0.5rem auto;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $tablet-width) {
    align-items: center;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &.centered {
    justify-content: center;
  }

  @media (max-width: $tablet-width) {
    flex-direction: column;
  }
}

.flex-inline-child {
  flex: 1 0 0;
  margin: 0.5rem auto;
  max-width: 95%;
}

.flex-column-child {
  display: flex;
  flex: 1;

  flex-direction: column;
  flex-basis: 100%;

  width: 100%;
}


.half {
  margin-left: auto;
  margin-right: auto;
  min-width: 50%;
  max-width: 50%;

  @media (max-width: $tablet-width) {
    min-width: 90%;
    max-width: 90%;
  }
}

.small {
  min-width: 30%;
  max-width: 30%;

  @media (max-width: $tablet-width) {
    max-width: 80%;
    margin: 1rem;
  }
}