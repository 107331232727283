header {
  display: block;
  text-align: center;
  padding: 1rem 0 2rem;
  margin: 0 0 2rem;
  background: linear-gradient(150deg, $brown 0%, $brown 50%, darken($brown, 3%) 50%, darken($brown, 4%) 100%);

  h2 {
    color: $white;
    margin: 3rem 0;
  }

  img {
    max-width: 90%;
  }

  &.condensed {
    padding: 1rem 0 0.5rem;
    text-align: left;

    img {
      margin: 0 .5rem;
      height: 50px;

      @media(max-width: $tablet-width) {
        height: 40px;
      }
    }

    h2 {
      display: inline-block;
      margin: .9rem;
      vertical-align: top;

      @media(max-width: $tablet-width) {
        font-size: 1.3rem;
      }
      @media(max-width: $mobile-width) {
        display: none;
      }
    }

    nav {
      float: right;
    }
  }
}
