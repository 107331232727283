* {
  box-sizing: border-box;
}

body {
  font-family: "Raleway", Helvetic, sans-serif, arial;
  color: $dark-blue;
  -webkit-font-smoothing: antialiased;
  background: $off-white;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.6em;
}

p {
  line-height: 160%;

  &.important {
    color: $subtle-grey;
    font-size: 1.3rem;
    font-style: italic;
  }
  &.emphasis {
    font-size: 1.2rem;
  }
  &.subtle {
    color: $subtle-grey;
    font-style: italic;
  }
}

a {
  color: $medium-blue;
}

img {
  border: none;
}

table {
  border: none;
  width: 100%;
}
td {
  padding: .3rem;
}

blockquote {
  margin: 2rem .5rem;
  font-size: 1.1rem;
  line-height: 1.5;
  padding: .2rem 1rem;
  border-left: 3px solid $dark-blue;
}

.padded {
  padding: 1rem;
}
.no-margin {
  margin: 0;
}

.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}

.title-emphasis {
  text-align: center;
  &:before {
    content: " ";
    position: absolute;
    width: 50%;
    min-width: 100px;
    background: transparentize($subtle-blue, .9);
    height: .5rem;
    left: 0;
    right: 0;
    z-index: 0;
    margin: 16px auto;
  }
}

.box {
  min-width: 30%;

  margin: 1rem;
  padding: 1rem;

  background: $white;
  box-shadow: $box-shadow;

  @media (max-width: $mobile-width) {
    margin: 2rem 0;
  }

  &.no-padding {
    padding: 0;
  }
  &.left {
    border-left: 3px solid $dark-blue;
  }
  &.right {
    border-right: 3px solid $dark-blue;
  }
  &.emphasis {
    border-bottom: 3px solid $dark-blue;
  }
  &.dark {
    background-color: $subtle-blue;
    border: 1px solid $dark-blue;
    color: $white;
  }
  &.inner {
    margin: 1.5rem auto;
    max-width: 80%;

    @media (max-width: $mobile-width) {
      max-width: 90%;
    }
  }
}

.container-accent {
  position: relative;
  margin: .5rem 0;
  padding: 1rem 0;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-color: $subtle-grey;
    transform: skewY(3deg);
  }
}

img {
  object-position: center;
  object-fit: fill; // for IE11
  object-fit: cover;

  &.small {
    margin: 0 1rem;
    max-width: 40%;

    @media (max-width: $tablet-width) {
      max-width: 70%;
      margin: 1rem;
      min-width: 150px;
    }
  }
  &.v-small {
    margin: 0 1rem;
    max-width: 25%;

    @media (max-width: $tablet-width) {
      max-width: 40%;
      margin: 1rem;
      min-width: 150px;
    }
  }
}

.framed {
  background: $white;
  padding: .4rem;
  box-shadow: $box-shadow;
}

.button {
  display: inline-block;
  padding: .6em 1.6em;
  margin: 1rem .5rem;
  text-align: center;
  font-size: 1em;
  letter-spacing: .1rem;

  text-transform: uppercase;
  text-decoration: none;

  border-radius: .2rem;
  cursor: pointer;
  color: $off-white;
  background-color: $subtle-blue;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);

  transition: background-color .5s ease;
  &:hover {
    background-color: $dark-blue;
  }
}

.image-heading {
  padding: 0 .5rem;

  &.coffee {
    background-image: url("/images/coffee.png");
  }
  &.cakes {
    background-image: url("/images/cakes.png");
  }

  .text {
    font-size: 2rem;
    display: inline-block;
    margin: .6rem;
    padding: 1rem 2rem;

    color: $white;
    background-color: transparentize($dark-blue, .2);
  }
}

.map, .map-fallback {
  width: 100%;
  height: 16rem;
  background-position: center;
}

.map-fallback {
  background-image: url("/images/map.png");
}