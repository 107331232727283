nav {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  background-color: $white;
  padding: 1rem 0.5rem;
  text-align: left;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  transition: border-radius 0.4s linear;

  &.condensed {
    @media (max-width: $tablet-width) {
      padding: 0.4rem 0.4rem 0.2rem 0;
    }
  }

  &:hover {
    border-radius: 0.2rem;

    &.condensed {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.nav-bar {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0;

    &.narrow {
      @media (min-width: $mobile-width) {
        display: none;
      }
    }

    &.wide {
      @media (max-width: $mobile-width) {
        display: none;
      }
    }

    &.tablet {
      display: none;
      @media (max-width: $tablet-width) {
        display: block;
      }
    }

    &.desktop {
      @media (max-width: $tablet-width) {
        display: none;
      }
    }

    a {
      color: $medium-blue;
      text-decoration: none;
      font-size: 1.1em;
      padding: 0.5em;
      transition: all 0.4s ease;
      margin: 0 0.2rem;
      border-radius: 0.1rem;

      &.open-menu {
        padding: 0;
        margin: 0 .5rem 0 0;
        img {
          width: 30px;
          height: 30px;
        }
      }

      &:hover {
        border-radius: 0.2rem;
        color: $off-white;
        background-color: $medium-blue;

        &.open-menu {
          background: none;
        }
      }
    }
  }
}

.mobile-nav-button {
  background-image: url("/images/menu.png");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  position: relative;
  display: none;
  padding-right: 0.8rem;
}

.overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;

  opacity: 0;
  visibility: hidden;

  background: transparentize(black, 0.3);
  transition: visibility 0.3s ease-in, opacity 0.3s ease-in;


  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.mobile-nav {
  display: flex;
  position: fixed;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 101;

  height: 100%;
  padding: 1rem;
  width: 250px;
  top: 0;
  right: -275px;

  background: url('/images/cup.png') 100% 100% no-repeat $dark-blue;
  transition: transform .5s ease;

  transform: translateX(0);

  &.active {
    transform: translateX(-275px);
  }

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;

    img {
      height: 35px;
      width: 35px;
    }
  }

  ul {
    margin: 0 0 5rem;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: $off-white;
    font-size: 1.4em;
    text-decoration: none;
  }

  li {
    position: relative;
    margin: 4rem 0;

    &:after {
      content: '';
      position: absolute;
      display: block;
      margin: 0 auto;

      width: 100%;
      height: 0.2rem;
      background-color: $off-white;

      bottom: -2rem;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}