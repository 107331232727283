footer {
    margin: 2rem 0 0;
    padding: 2rem;

    background: linear-gradient(150deg, $dark-blue 0%, $dark-blue 70%, lighten($dark-blue, 3%) 70%, lighten($dark-blue, 4%) 100%);
    color: $off-white;
    text-align: center;

    a {
        color: $off-white;
    }
}